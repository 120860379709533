import React, { useContext } from 'react';
import { GlobalDataContext } from '../../context/context';
import { ButtonContent } from '../global/boton/ButtonContent';



const BlockNine = ({ title, text, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
      <section class="relative bg-[url(https://images.unsplash.com/photo-1604014237800-1c9102c219da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80)] bg-cover bg-center bg-no-repeat">
        <div class="absolute inset-0 bg-white/75 sm:bg-transparent sm:from-white/95 sm:to-white/25 sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"></div>

        <div class="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8">
          <div class="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
            {sloganPrincipal ? (
              rpdata?.dbPrincipal?.licensed ? (
                <h4 className="text-3xl font-extrabold sm:text-5xl">
                  {rpdata?.dbPrincipal?.licensed}
                </h4>
              ) : (
                <h3 className="text-3xl font-extrabold sm:text-5xl">
                  {rpdata?.dbPrincipal?.exprYears
                    ? `we have ${rpdata?.dbPrincipal?.exprYears} years of experience`
                    : "we have many years of experience"}
                </h3>
              )
            ) : (
              <h2 className="text-3xl font-extrabold sm:text-5xl">
                {title ? (
                  title
                ) : (
                  <span className="lowercase text-[18px]">
                    {`default title={'frase'}`} o
                    {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                  </span>
                )}
              </h2>
            )}
            <p className="mt-4 max-w-lg sm:text-xl/relaxed">
              {text ? (
                text
              ) : (
                <span>
                  {`para agregar el texto -> text={'description'}`}
                  <br />
                  {"para agregar lista de about -> listsAbout={true}"}
                  <br />
                  {"para agregar lista de servicios -> listsServices={true}"}
                </span>
              )}
            </p>
            <div class="">
              <ButtonContent btnStyle='three'/>
            </div>
          </div>
        </div>
      </section>
    );
}


export default BlockNine;