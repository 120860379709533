
  

export const firebaseConfig = {
    //variables
    apiKey: "AIzaSyAWoBhZO31UWo1P8swWSc9c0JAfilt4Z6I",
    authDomain: "clientsreviews-b613a.firebaseapp.com",
    projectId: "clientsreviews-b613a",
    storageBucket: "clientsreviews-b613a.appspot.com",
    messagingSenderId: "417612883679",
    appId: "1:417612883679:web:ec1d24243985ea4be92b6a",
    measurementId: "G-E7V7GC7EXE"
  };
  