import React, { useContext } from 'react';
// import { VscDebugBreakpointData } from 'react-icons/vsc';
import { GlobalDataContext } from '../../context/context';
import { ButtonContent } from '../global/boton/ButtonContent';

import imgDefault from '../../assets/image/placeholder.png'


const BlockTen = ({ title, text, listsAbout, image, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
      <>
        {/* <!-- Snippet --> */}
        <section className="border-4 border-lime-700 m-16 flex flex-col justify-center antialiased text-gray-200 min-h-screen">
          <div className="max-w-6xl mx-auto p-4 sm:px-6 h-full">
            {/* <!-- Blog post --> */}
            <article className="max-w-sm mx-auto md:max-w-none grid md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 xl:gap-16 items-center">
              <div className="relative block group">
                <div
                  className="absolute inset-0 bgBloque hidden md:block transform md:translate-y-2 md:translate-x-4 xl:translate-y-4 xl:translate-x-8 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none"
                  aria-hidden="true"
                ></div>
                <div className="relative h-0 pb-[56.25%] md:pb-[75%] lg:pb-[56.25%] overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out">
                  <img
                    className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                    src={image ? image : imgDefault}
                    alt="Blog post"
                  ></img>
                </div>
              </div>
              <div>
                <header>
                  <div className="mb-3">
                    {listsAbout ? (
                      <ul className="flex flex-wrap text-xs font-medium -m-1">
                        {rpdata?.dbAbout?.[0].list.length > 1
                          ? rpdata?.dbAbout?.[0].list.map((item, index) => {
                              return (
                                <li key={index} className="m-1">
                                  <div class="flex justify-between mb-1">
                                    <span class="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-gradient-to-r from-yellow-400 via-lime-700 to-black hover:bg-purple-700 transition duration-150 ease-in-out">
                                      {item}
                                    </span>
                                  </div>
                                </li>
                              );
                            })
                          : null}
                      </ul>
                    ) : null}
                  </div>
                  <h3 className="bg-gradient-to-r from-green-400 via-lime-600 to-lime-900 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl">
                    {sloganPrincipal ? (
                      rpdata?.dbPrincipal?.licensed.length > 1 ? (
                        <span className=" capitalize">
                          {rpdata?.dbPrincipal?.licensed}
                        </span>
                      ) : (
                        <span className=" capitalize">
                          we have {rpdata?.dbPrincipal?.exprYears} years of
                          experience{" "}
                        </span>
                      )
                    ) : (
                      <span className=" capitalize">
                        {title ? (
                          title
                        ) : (
                          <span className="lowercase text-[18px]">
                            {`default title={'frase'}`} o
                            {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                          </span>
                        )}
                      </span>
                    )}
                  </h3>
                </header>
                <p className="text-lg text-black flex-grow">
                  {text ? (
                    text
                  ) : (
                    <span>
                      {`para agregar el texto -> text={'description'}`}
                      <br />
                      {"para agregar lista de about -> listsAbout={true}"}
                      <br />
                      {
                        "para agregar lista de servicios -> listsServices={true}"
                      }
                    </span>
                  )}
                </p>
                <ButtonContent />
              </div>
            </article>
          </div>
        </section>
      </>
    );
}


export default BlockTen;