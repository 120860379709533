import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import ValuesOne from "../components/values/Values_2";

function Financing() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Financing">
      <>
        <TransparentHeader
          // headertitle="Financing"
          Subheader="Financing"
          bgimg={`${rpdata?.gallery?.[19]}`}
        />
        <div className="w-full h-auto py-4  border flex justify-center items-center">
          <div className="w-[100%] h-auto px-2">

            <a className="block w-[320px] md:w-[400px] lg:w-[500px] mx-auto pt-[20px]"
              href="https://www.acornfinance.com/apply?d=MCMD4&utm_medium=web_banner"
              target="_blank"
              rel="noreferrer">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/imagenes-4d2f6.appspot.com/o/acorn-finance-banner-easy-payment-options-vertical-large.png?alt=media&token=6e9ff91b-de20-40da-9a97-f4eef76e8a64"
                alt="financiamiento"
                className=" border-black border-[3px] rounded-3xl"
              />
            </a>

            <ValuesOne image={rpdata?.stock?.[5]} />

            <div className="w-full mx-auto h-auto py-4">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/imagenes-4d2f6.appspot.com/o/descarga%20(1).png?alt=media&token=0b1b96b2-983a-448a-83e4-7516f7083716"
                alt="informacion"
                className="w-full h-full"
              />
            </div>


          </div>
        </div>
      </>

    </BaseLayout>
  );
}

export default Financing;
